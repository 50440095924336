;function resizeGridItem(item){
   var grid = document.getElementsByClassName("gallery")[0];
   var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
   var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
   var rowSpan = Math.ceil((item.querySelector('.gallery-item-image').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
   item.style.gridRowEnd = "span " + rowSpan;
}

function resizeAllGridItems(){
   var allItems = document.getElementsByClassName("gallery-item");
   for(index = 0; index < allItems.length; index++) {
      imagesLoaded(allItems[index], resizeInstance);
   }
}

function resizeInstance(instance){
   var item = instance.elements[0];
   resizeGridItem(item);
}